<template>
  <div id="our-story">
    <!-- Our Story -->
    <!-- <div class="row text-image left-aligned">
      <div class="col-md-6 order-md-2 image-content"  data-aos="fade-left">
        <img src="@/assets/home/our-story/img.png" alt="Connected" />
      </div>
      <div class="col-md-6 order-md-1 text-content basic-text-hp"  data-aos="fade-right">
        <div class="Aligner">
          <div class="Aligner-item Aligner-item--top"></div>
          <div class="Aligner-item">
            <h1>Connected</h1>
            <p>Our curated ecosystem seamlessly blends the evolving physical and digital landscape offering authentic connectivity. Honoring the importance of biophilic principles and human-centric design, at a five-star level, the transformational @Ease experience enhances productivity and collaboration in the comfort of your chosen workplace. In a moment where personal space plays such a critical role, our team makes the promise that you will feel our personal touch to your chosen work environment.  Our services and professionals will foster the transition of home and work, work and play, and play host to all of our guests.</p>
          </div>
          <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
      </div>
    </div> -->
    <!-- The spaces -->
    <!-- <div class="row text-image right-aligned">
      <div class="col-md-6 image-content"  data-aos="fade-right">
        <img src="@/assets/home/our-story/img2.png"  alt="Transformational"/>
      </div>
      <div class="col-md-6 text-content basic-text-hp"  data-aos="fade-left">
        <div class="Aligner">
          <div class="Aligner-item Aligner-item--top"></div>
          <div class="Aligner-item">
            <h1>Transformational</h1>
            <p>The guided journey of Ease begins with a feeling the moment you engage in the experience. Seasoned experts tailor a path to productivity transporting you to a state of uninhibited innovation.  Our principles promote and embrace the importance of expression, creativity, and place a premium on supporting the Arts as a form of emotional intelligence.  Our holistic approach understands the value of mind, body and soul.</p>
          </div>
          <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
      </div>
    </div> -->
    <!-- The Ease Hospitality -->
    <!-- <div class="row text-image left-aligned">
      <div class="col-md-6 order-md-2 image-content"  data-aos="fade-left">
        <img src="@/assets/home/our-story/img3.png" alt="Authentic"/> -->
        <!-- // <p>Design intent within our space reflects our understanding of the importance of  nature and its impact on health and well-being.</p> -->
      <!-- </div>
      <div class="col-md-6 order-md-1 text-content"  data-aos="fade-right">
        <div class="Aligner">
          <div class="Aligner-item Aligner-item--top"></div>
          <div class="Aligner-item"> -->
            <!-- // <img class="logo-gfx" src="@/assets/home/our-story/vector-line.svg" /> -->
            <!-- <h1>Authentic</h1>
            <p>Rooted in powerful efforts that span from sustainability to philanthropy, @Ease stands with movements that have meaning. Daily efforts, both onsite and virtually, adhere to environmentally–friendly practices.  From using recycled products, to partnering with our local organizations to provide for those in need, @Ease is genuinely committed to taking care of the planet and the people on it.</p>
            <p>Our design intent within our space reflects our understanding of the importance of nature and its impact on health and well-being.  We are devoted to enhancing the quality of life and productivity through the calm connectivity of Mother Nature… and that is why we are doing our part to take care of Her.</p> -->
            <!-- // <div class="download-buttons">
              <a href="https://apps.apple.com/kh/app/ease-workplace/id1526255927" target="_blank"><img src="@/assets/luxury-amenities/app-store-transparent.svg" /></a>
              <a href="https://play.google.com/store/apps/details?id=com.risesoftware.atease&hl=en_US&gl=US" target="_blank"><img src="@/assets/luxury-amenities/play-store-transparent.svg" /></a>
            </div> -->
          <!-- </div>
          <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
      </div>
    </div> -->

    <!-- Locations -->
    <div class="text-full-width text-centered pb-3" data-aos="fade-in">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>Locations</h1>
        </div>
      </div>
    </div>

    <div class="locations" v-resize="onResize">
    <NavigationBar />
    <div class="slider-container paginated px-5">
      <div
        v-if="!isMobile()"
        :per-page=1
        :paginationEnabled=false
        :value=currentDesktopPageIdx>

        <!-- Location Set 1 Slide Sample -->
        <div v-for="(group, groupIdx) in getDesktopSlider()" :key="`group-${groupIdx}`">

            <div class="row slide ">
              <div :class="`${group.colsize} desktop location-col`"
                    v-for="(location, locIdx) in group.locations" :key="`location-${groupIdx}-${locIdx}`" style="padding-right:1%; padding-left: 1%;">
                <router-link :to="location.link">
                  <img :src="location.image" class="location-content" alt="Location" />
                  <div class="dark-overlay">
                    <img :src="location.logo" class="location-logo desktop" alt="Location Logo"/>
                    <div class="location-text">
                      <h1>{{location.name}}</h1>
                      <h6>{{location.city}}</h6>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
        </div>

        <!-- End of Carousel -->
      </div>

        <div v-if="isMobile()">
          <div v-for="(location, locIdx) in this.locations" :key="`location-${locIdx}`" class="location-padding">
            <div class="row slide">
              <div class="col-md-12 location-col">
                <router-link :to="location.link">
                  <img :src="location.image" class="location-content" />
                  <div class="width-mobile dark-overlay">
                    <img :src="location.logo" class="location-logo mobile"/>
                    <div class="location-text">
                      <h1>{{location.name}}</h1>
                      <h2>{{location.city}}</h2>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>

    </div>
  </div>

  <div class="p-5"></div>

    <div class="navigation-icons">
      <div class="features-wrapper" data-aos="fade-up" data-aos-delay="400">
        <div class="feature-item" data-aos="fade-in" data-aos-delay="800">
          <router-link to="about-us#meetings-and-events">
            <img src="@/assets/home/banner-icons/meetings-events.svg" alt="Hybrid Meetings & Events"/>
            <p>Meetings &amp; Events</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="500">
          <router-link to="about-us#full-service-amenities-spaces">
            <img src="@/assets/home/banner-icons/tenant-lounge.svg" alt="Tenant Lounge"/>
            <p>Tenant Lounge</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="600">
          <router-link to="about-us#seamless-solutions">
            <img src="@/assets/home/banner-icons/food-beverage.svg" alt="Food & Beverage"/>
            <p>Food &amp; Beverage</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="700">
          <router-link to="about-us#workplace-solutions">
            <img src="@/assets/home/banner-icons/workspace.svg" alt="Workspace"/>
            <p>Digital</p>
          </router-link>
        </div>
        <div class="feature-item" data-aos="fade-in" data-aos-delay="900">
          <router-link to="about-us#seamless-solutions">
            <img src="@/assets/home/banner-icons/wellness.svg" alt="Wellness"/>
            <p>Wellness</p>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Feedback/Reviews Carousel -->
    <carousel
      :per-page=1
      @pageChange="handlePageChange"
      :value=currentReviewPageIdx
      :paginationEnabled=false>
      <slide>
        <div class="reviews-section">
          <img src="@/assets/home/our-story/quotation.svg" alt="Quotation"/>
          <p class="feedback-italic">
            “Ease Hospitality was beyond our expectations. We hosted our International Team, who have traveled the world, and they all agreed that this was the most high-tech and gorgeous event space we have ever experienced. The food was delicious (highly recommend the cookies) and the service was impeccable. Their team even swept through our meeting space at each break to clean and tidy up behind us. If you choose this space you will be satisfied. We can’t wait to return."
          </p>
          <div class="review-profile">
            <div class="review-logo-container">
              <img class="review-logo" src="@/assets/home/our-story/reviewer-logo-2.png" alt="Reviewer Logo"/>
            </div>
            <div class="review-author">
<!--               <span>Chris Metts</span><br> -->
              <span>Aetrex</span>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="reviews-section">
          <img src="@/assets/home/our-story/quotation.svg" alt="Quotation"/>
          <p class="feedback-italic">
            "They were thrilled and want to return next year, @Ease 605 specifically.  This is because more than 50% of the attendees have never been to NYC and they feel the “above ground” views really add to the NYC experience.  Tom Stowell (planner) thanked the Ease team in on-stage speech."
          </p>
          <div class="review-profile">
            <div class="review-logo-container">
              <img class="review-logo" src="@/assets/home/our-story/reviewer-logo-7.png" alt="Reviewer Logo"/>
            </div>
            <div class="review-author">
<!--               <span>Tom Stowell</span><br> -->
              <span>Georgetown University</span><br>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="reviews-section">
          <img src="@/assets/home/our-story/quotation.svg" alt="Quotation"/>
          <p class="feedback-italic">
             "USAID made a point to gather Austin and team to extend their thank-yous.  They felt that the space was so fresh "unlike a hotel ballroom" and that there was never a no."
          </p>
          <div class="review-profile">
            <div class="review-logo-container">
              <img class="review-logo" src="@/assets/home/our-story/reviewer-logo-5.png" alt="Reviewer Logo"/>
            </div>
            <div class="review-author">
<!--               <span>Adam Baron</span><br> -->
              <span>USAID</span><br>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="reviews-section">
          <img src="@/assets/home/our-story/quotation.svg" alt="Quotation"/>
          <p class="feedback-italic">
             "It was a pleasure to work with you all. Our event was successful thanks to all of you. Your place and hospitality was great, and I’m sure we will have opportunities to work together again in the future as well.  Thank you all for your work and cooperation!"
          </p>
          <div class="review-profile">
            <div class="review-logo-container">
              <img class="review-logo" src="@/assets/home/our-story/reviewer-logo-6.png" alt="Reviewer Logo"/>
            </div>
            <div class="review-author">
              <span>Mission of Japan</span><br>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="reviews-section">
          <img src="@/assets/home/our-story/quotation.svg" alt="Quotation"/>
          <p class="feedback-italic">
             "Your team was incredible! Everyone loved the space and the food was delicious!  Lauren was outstanding! I would love to talk about your availability in May at either venue for a C-Suite Event for 125ppl"
          </p>
          <div class="review-profile">
            <div class="review-logo-container">
              <img class="review-logo" src="@/assets/home/our-story/reviewer-logo-4.png" alt="Reviewer Logo"/>
            </div>
            <div class="review-author">
<!--               <span>Lynnette Camacho</span><br> -->
              <span>Sportico</span><br>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="reviews-section">
          <img src="@/assets/home/our-story/quotation.svg" alt="Quotation"/>
          <p class="feedback-italic">
             "Thank you so much for all the arrangements and hospitality!  Many guests loved the stylish and cozy atmosphere of @Ease, and Ambassadors were also satisfied. Guests smiled when they were given confectionary gifts, for which we are grateful to Marcos’s help. We look forward to the next time working with you all."
          </p>
          <div class="review-profile">
            <div class="review-logo-container">
              <img class="review-logo" src="@/assets/home/our-story/reviewer-logo-6.png" alt="Reviewer Logo"/>
            </div>
            <div class="review-author">
              <span>Mission of Japan</span><br>
            </div>
          </div>
        </div>
      </slide>
      <slide>
        <div class="reviews-section">
          <img src="@/assets/home/our-story/quotation.svg" alt="Quotation"/>
          <p class="feedback-italic">
             "The breakfast was wonderful, GP staff was great and welcoming, and overall they were happy with the program and our team"
          </p>
          <div class="review-profile">
            <div class="review-logo-container">
              <img class="review-logo" src="@/assets/home/our-story/reviewer-logo-1.png" alt="Reviewer Logo"/>
            </div>
            <div class="review-author">
<!--               <span>Chris Elfteriades</span><br> -->
              <span>Alliance Bernstein</span><br>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
    <div class="review-pagination bullets gold-bg-reviews">
      <div :class="{selected: this.currentReviewPageIdx == 0}" @click="goToReviewPage(0)">
      </div>
      <div :class="{selected: this.currentReviewPageIdx == 1}" @click="goToReviewPage(1)">
      </div>
      <div :class="{selected: this.currentReviewPageIdx == 2}" @click="goToReviewPage(2)">
      </div>
      <div :class="{selected: this.currentReviewPageIdx == 3}" @click="goToReviewPage(3)">
      </div>
      <div :class="{selected: this.currentReviewPageIdx == 4}" @click="goToReviewPage(4)">
      </div>
      <div :class="{selected: this.currentReviewPageIdx == 5}" @click="goToReviewPage(5)">
      </div>
      <div :class="{selected: this.currentReviewPageIdx == 6}" @click="goToReviewPage(6)">
      </div>
    </div>

    <!-- Full image -->
    <div class="image-full-width" data-aos="fade-in" style="margin-top:0 !important;">
      <img src="@/assets/home/our-story/img4.png" alt="Our Story"/>
    </div>

    <!-- In the News -->
    <div class="text-full-width text-centered news-section" data-aos="fade-in">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item" style="width: 100%;">
          <h1>In the News</h1>
          <NewsCardList />
          <hr class="section-divider" />
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>

    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in">
      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To<br>
          Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#our-story {
  .text-image div.image-content img  {
    object-fit: cover;
    width: 100% !important;
    /* height: 500px !important; */
  }
}
@import '@/styles/page-body.scss';

.VueCarousel-inner {
  visibility: visible;
  flex-basis: 100%;
  width: 100%;
}

.modal-open {
    overflow-y: scroll;

}
.about-us-page{
  .text-content{
    a{
      color: $palm-green !important;
    }
  }
}

#seamless-solutions{
  a.generic-link{
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.2em;
    /* text-transform: lowercase; */
    border-bottom: 2px solid #EEC6BB;
  }
}
@media only screen and (max-width: 1023px) {
  h1.meet-our-team-header{
    text-align: center !important;
    margin: 0 !important;
  }
}

#person-modal{

  .gradient-separator {
    width: 100%;
    padding: 0 12.5%;
    height: 2px;
    margin: 32px auto 12px 0;
  }
  .modal-dialog{
    max-width: 600px;
  }
  .person-modal{
    background-color: $black;
    border-radius: 0;
    border-width: 0;
    .modal-header{
      display: none;


    }
    .modal-body{
      padding: 0;
      color: $ivory;
      .image-content{
        .VueCarousel{
          height: 100%;
          img{
            object-fit: cover;
            width: 100%;
            padding: 24px;
            padding-bottom: 0;
          }
        }
      }

      .title {
        font-size: 16px !important;
        color: rgba(#fff, .85);
      }
      .text-content{
        padding: 8%;
        p.desc{
          font-size: 18px;
          padding-top: 1em;
        }
        p.email{
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 1em;

          & > a {
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            border-bottom: 2px solid #EEC6BB;
            color: white;
          }
        }
      }
    }
    .sns-logo-containers{
      margin-top: 15px;
    }
    .modal-footer{
      display: none;
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .modal-body{
      .text-content{
        padding-top: 40px !important;
        padding-bottom: 20px !important;
        h1{
          font-size: 30px !important;
          margin-bottom: 4px !important;
        }
        p.desc{
          font-size: 16px !important;
        }
        p.email{
          font-size: 16px !important;
        }
      }
    }
    .width-mobile {
      width: 30% !important;
    }
    /** End of Mobile */
  }
}

.news-section {
  margin-top: 40px;
}

.section-divider {
  border-top: 1px solid #D6BF9B;
  margin-top: 7rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.locations{
  .slider-container{
    padding-top: 25px;
    text-align: center;
  }
  .location-col{
    max-height: 80vh;
    &.desktop{
      padding: 0;
    }
    &.mobile{
      .row.slide{
        margin-left: 0;
        margin-right: 0;
      }
    }
    position: relative;
    .dark-overlay {
      position: absolute; /* Sit on top of the page content */
      width: 96%; /* Full width (cover the whole page) */
      height: 100%; /* Full height (cover the whole page) */
      top: 0;
      left: 2%;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.7); /* Black background with opacity */
      z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
      cursor: pointer; /* Add a pointer on hover */

      .location-text{
        color: white;
        position: absolute;
        bottom: 15%;
        width: 100%;
      }

      @media only screen and (max-width: 502px) {
        .location-text {
          color: white;
          position: absolute;
          bottom: 10%;
          width: 100%;

          h1 {
            font-size: 2em !important;
          }

          h4 {
            font-size: 1em !important;
          }
        }
      }

      @media only screen and (max-width: 352px) {
        .location-text {
          color: white;
          position: absolute;
          bottom: 10%;
          width: 100%;

          h1 {
            font-size: 1.7em !important;
          }

          h4 {
            font-size: 0.7em !important;
          }
        }
      }
    }
  }
  .location-content{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .paginated{
    .paginator-arrows{
      img{
        &:first-child{
          left: 2%;
        }
        &:last-child{
          right: 2%;
        }
      }
    }
  }

  .location-logo{
    height: 95%;
    &.desktop{
      width: 50%;
    }
    &.mobile{
      width: 65%;
    }
  }

  .location-padding {
    padding-bottom: 1.25rem;
  }
  .location-spacebetween {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}

.image-full-width {
  width: 100%;
}

</style>

<script>
import NewsCardList from '@/components/home/NewsCardList.vue';

export default {
  components: {
    NewsCardList,
  },
  name: 'OurStory',
  data() {
    return{
      locations: [
        // Location 1
        {
          name: "1345 AOA",
          city: "New York, NY",
          link: "/1345",
          image: require(`@/assets/locations/location-1.png`),
          logo: require(`@/assets/ease-meetings-logo.svg`)
        },
        // Location 2
        {
          name: "605 3rd Ave",
          city: "New York, NY",
          link: "/605",
          image: require(`@/assets/locations/location-2.png`),
          logo: require(`@/assets/ease-605-logo.svg`)
        },
        // Location 3
        // {
        //   name: "Virtual",
        //   city: "Anywhere",
        //   link: "/virtual",
        //   image: require(`@/assets/locations/location-3.png`)
        // },
      ],
      currentDesktopPageIdx: 0,
      currentMobilePageIdx: 0,
      currentReviewPageIdx: 0,
    }
  },
  methods: {
    getDesktopSlider(){
      // gets the number of groups based on the listed location
      var groupsCount = Math.ceil(this.locations.length/4)

      //initializes slider groups array
      var sliderGroups = new Array()
      for(var i = 0; i < groupsCount; i++){
        // initializes group locations
        var groupLocations = new Array()
        var locationsPerPage = 2
        //will add to group based on locations base idx
        var base = i * locationsPerPage

        for(var idx = base; idx < base + locationsPerPage; idx++){
          if(idx < this.locations.length){
            // will add
            groupLocations.push(this.locations[idx])
          }
        }

        var group = {
          group: `Group ${i + 1}`,
          colsize: `col-md-${12 / locationsPerPage}`,
          locations: groupLocations
        }
        sliderGroups.push(group)
        // adds the new slider group
        // console.log(`Added group: ${sliderGroups[i].group}`)
        // console.log(`Col size: ${sliderGroups[i].colsize}`)
        // console.log(`Locations: ${sliderGroups[i].locations.length}`)
      }

      return sliderGroups
    },
    previousPage(){
      if(!this.isMobile()){
        // desktop
        this.currentDesktopPageIdx = Math.max(this.currentDesktopPageIdx - 1, 0)
      }else{
        // mobile
        this.currentMobilePageIdx =  Math.max(this.currentMobilePageIdx - 1, 0)
      }
    },
    nextPage(){
      if(!this.isMobile()){
        // desktop
        this.currentDesktopPageIdx = Math.min(this.currentDesktopPageIdx + 1, this.getDesktopSlider().length - 1)
      }else{
        // mobile
        this.currentMobilePageIdx = Math.min(this.currentMobilePageIdx + 1, this.locations.length - 1)
      }
    },
    isLeftPaginatorVisible(){
      if(!this.isMobile()){
        // desktop
        return this.currentDesktopPageIdx > 0
      }else{
        // mobile
        return this.currentMobilePageIdx > 0
      }
    },
    isRightPaginatorVisible(){
      if(!this.isMobile()){
        // desktop
        return this.currentDesktopPageIdx < this.getDesktopSlider().length - 1
      }else{
        // mobile
        return this.currentMobilePageIdx < this.locations.length - 1
      }
    },
    goToReviewPage(reviewIdx){
      this.currentReviewPageIdx = reviewIdx
    },
    handlePageChange(reviewIdx){
      this.currentReviewPageIdx = reviewIdx
    },
    autoReviewCarousel(){
      if(this.currentReviewPageIdx != 6){
        var autoIdx = this.currentReviewPageIdx + 1
        this.goToReviewPage(autoIdx)
        this.handlePageChange(autoIdx)
        console.log(this.currentReviewPageIdx)
      }else{
        this.goToReviewPage(0)
        this.handlePageChange(0)
        console.log(this.currentReviewPageIdx)
      }
    }
  },
  mounted: function(){
    window.setInterval(() => {
      this.autoReviewCarousel()
    }, 5000)
  }
}
</script>
