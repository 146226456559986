<template>
  <div class="contact main">
    <NavigationBar />
    <div class="row contact-text-form">
      <div class="contact-form-outer">
        <div class="contact-form-inner">
          <div class="col-sm-5 text-form-item">
            <div class="contact-details">
              <p>
                Ease Hospitality<br>
                Phone: 212-201-0425<br>
                Email: info@easehospitality.com
              </p>
              <h2>
                Follow Us
              </h2>
              <div class="sns-icons">
                <a href="https://www.facebook.com/Ease-Hospitality-106482987830969" target="_blank" class="sns-logo">
                  <img src="@/assets/contacts/facebook-dark-icon.svg" alt="facebook-dark-icon"/>
                </a>
                <a href="https://www.instagram.com/ease.hospitality" target="_blank" class="sns-logo">
                  <img src="@/assets/contacts/instagram-dark-icon.svg" alt="instagram-dark-icon" />
                </a>
                <a href="https://www.linkedin.com/company/easehospitality/" target="_blank" class="sns-logo">
                  <img src="@/assets/contacts/linkedin-dark-icon.svg" alt="linkedin-dark-icon" />
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-7 text-form-item">
            <!-- Contact Form -->
            <form class="contact-form" @submit.prevent="checkForm($event)" method="post">
              <!-- Name -->
              <input
                type="text"
                name="name"
                aria-label="name"
                placeholder="Your Name*"
                v-model="form.name"
                required
              />

              <!-- Email -->
              <input
                type="email"
                name="email"
                aria-label="email"
                placeholder="Your Corporate Email*"
                v-model="form.email"
                required
              />

              <!-- Phone -->
              <input
                type="number"
                name="phone"
                aria-label="phone"
                placeholder="Your Phone Number*"
                v-model="form.phone"
                required
              />

              <!-- Reason for Contacting -->
              <div class="select">
                <select
                  aria-label="reason"
                  name="reason"
                >
                  <option selected disabled>Reason for Contacting</option>
                  <option>Meetings &amp; Events Spaces</option>
                  <option>Hospitality Management</option>
                  <option>General Inquiry</option>
                </select>
                <img src="@/assets/contacts/down-caret.svg" alt="dropdown"/>
              </div>

              <!-- Location dropdown -->
              <div class="select">
                <select
                  aria-label="location"
                  name="location"
                >
                  <option selected disabled>Location</option>
                  <option>1345</option>
                  <option>605</option>
                  <option>No Preference</option>
                </select>
                <img src="@/assets/contacts/down-caret.svg" alt="dropdown"/>
              </div>

              <!-- How did you hear about us? -->
              <input
                aria-label="source"
                type="text"
                name="source"
                placeholder="How did you hear about us?"
                v-model="form.source"
              />

              <!-- Additional Message / Info -->
              <textarea aria-label="message" rows="4" placeholder="Message" v-model="form.message">
              </textarea>
              <br />
               <vue-recaptcha ref="recaptcha" @verify="onVerify"  @expired="onExpired"
               :sitekey="contactForm.siteKey" :loadRecaptchaScript="true">  
               </vue-recaptcha>

                <div><p style="color: red">{{ contactForm.pleaseTickRecaptchaMessage }}</p></div>
               
                <button type="submit">SUBMIT</button>
                <br />
               
              <!-- End of Contact Form -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"
    :is-full-page="fullPage"></loading>
  </div>
</template>

<style lang="scss">
@import "../styles/contact.scss";
</style>

<script>
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'

// Import component
import Loading from 'vue-loading-overlay';
import NavigationBar from '@/components/NavigationBar.vue'
import {ContactForm} from "@/models/ContactForm";
import {API} from "@/API";

//Recaptcha component
import VueRecaptcha from 'vue-recaptcha';

var recaptchaMessage = "Please complete the reCAPTCHA verification to proceed."; 

export default {
  name: 'Contact',
  data() {
    return {
      isLoading: false,
      fullPage: true,
      form: ContactForm.blank(),
      contactForm: {
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: '',
        siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
      }
    }
  },
  components: {
    Loading,
    NavigationBar,
    VueRecaptcha
  },
  methods: {

    onVerify() {
      this.contactForm.pleaseTickRecaptchaMessage = '';
      this.contactForm.recaptchaVerified = true;
    },

    onExpired() {
      this.contactForm.pleaseTickRecaptchaMessage = recaptchaMessage;
      this.contactForm.recaptchaVerified = false;
    },

    checkForm(event){
      if (!this.contactForm.recaptchaVerified) {
        this.contactForm.pleaseTickRecaptchaMessage = recaptchaMessage;
        return true; // prevent form from submitting
      }
      const $this = this;
      event.preventDefault()

      $this.isLoading = true;
      API.submitContact(this.form)
        .then(function(response){
          $this.isLoading = false
          console.log(response)

          $this.form = ContactForm.blank()

          Swal.fire(
            'Thank You!',
            'Your inquiry has been sent.',
            'success'
          )
        })
        .catch(function (error) {
          $this.isLoading = false
          console.log(error)
          // handle error
          Swal.fire(
            'Oops...',
            'Something went wrong!',
            'error'
          )
        })
    },
  }
}
</script>
